export const logoSrc = '/assets/svg/logo-p.svg'
export const logoTextSrc = '/assets/svg/logo-text.svg'
export const arrowImg = '/assets/arrow.png'
export const appleImg = '/assets/svg/apple.svg'
export const androidImg = '/assets/svg/android.svg'
export const webImg = 'assets/svg/web.svg'
export const checkImg = '/assets/svg/check.svg'
export const packageImage = '/assets/packageImage.png'
export const cancelSubscription = '/assets/svg/cancel.svg'
export const subscriptionHistory = '/assets/svg/history.svg'
export const packs = '/assets/svg/packs.svg'
export const active = '/assets/svg/active.svg'
export const menu = '/assets/svg/Menu.svg'
export const nocart = '/assets/svg/no-cart.svg'
export const appleStore = '/assets/svg/apple-store.svg'
export const androidStore = '/assets/svg/android-store.svg'
export const playIcon = '/assets/svg/play.svg'
export const infoIcon = '/assets/svg/info.svg'
export const brokenCable = '/assets/svg/broken-cable.svg'