export const genreTabs = [
    'ALL',
    'DRAMA',
    'ACTION',
    'ROMANCE',
    'CRIME',
    'COMEDY',
    'ADVENTURE',
    'ANIMATION',
 
    
  
]